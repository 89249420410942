import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";

import App from './containers/App';
import * as serviceWorker from './serviceWorker';

// Reset style
import { createGlobalStyle } from 'styled-components';
import reset from 'utils/styled-component-reset';

const GlobalStyle = createGlobalStyle`
    ${reset}

    @import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR&display=swap');
    @import url('https://fonts.googleapis.com/css?family=Source+Code+Pro&display=swap');

    body {
        font-family: 'Noto Sans KR', sans-serif;
    }
`;

ReactDOM.render(
    <React.Fragment>
        <GlobalStyle />
        <Router>
            <App />
        </Router>
    </React.Fragment>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
