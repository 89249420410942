import React from 'react';
import styled from 'styled-components';

import ImageMainMobile from 'images/mobile_main.jpg';
import ImageMain from 'images/main.jpg';

import useWindowDimensions from 'hooks/useWindowDimensions';

const StyledHeader = styled.div`

    height: 80vh;
    min-height: 500px;

    img {
        width: 100vw;
        height: 100%;

        background-color:red;
    }

`;

const StyledHeaderImage = styled.div.attrs(props => ({
    backgroundUrl: props.backgroundUrl || '',
}))`
    width: 100vw;
    height: 100%;
    background: url("${props => props.backgroundUrl}") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
`;

const Header = _ => {
    const { width } = useWindowDimensions();

    const backgroundUrl = width > 600 ? ImageMain : ImageMainMobile;

    return (
        <StyledHeader>
            <StyledHeaderImage backgroundUrl={backgroundUrl}/>
        </StyledHeader>
    );
};

export default Header;
