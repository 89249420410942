import React, { useState, useEffect } from 'react';
import { Intro, Header, Album, Board, Map, Grid } from 'layouts';
import styled from 'styled-components';
import test from 'images/Large-Triangles.svg';
import firebase from 'firebase/app';
import 'firebase/firestore';

const StyledMain = styled.div`
  background-image: url(${test});
`;
// Large-Triangles

function App() {
  const [commentRef, setCommentRef] = useState(null);

  useEffect(() => {
    // Initialize Cloud Firestore through Firebase
    firebase.initializeApp({
      apiKey: 'AIzaSyDXOUe9-gWH0KU5JPwxK_41Q7RI7mwz2SU',
      authDomain: 'walter-35de7.firebaseapp.com',
      projectId: 'wedding-invitation-e292b',
    });

    var db = firebase.firestore();
    setCommentRef(db.collection('comments'));
  }, []);

  return (
    <StyledMain className="App">
      <Grid width={'100%'} height={'100%'} gap={'0'}>
        <Header />
        <Intro />
        <Album />
        {/* <Map /> */}
        {!!commentRef && <Board id="board" comment={commentRef} />}
      </Grid>
    </StyledMain>
  );
}

export default App;
