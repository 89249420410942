import React from 'react';
import Typography from 'components/Typography';
import styled from 'styled-components';

import IconHeart from 'images/heart.svg';
import ImageBumhee from 'images/bumhee.png';
import ImageJinyoung from 'images/jinyoung.png';
import ImagePhone from 'images/phone.svg';
import ImageSms from 'images/sms.svg';

const StyledHeaderWrapper = styled.div`
  margin: auto;
  display: flex;
`;

const StyledHeader = styled.div`
  max-width: 500px;
  min-height: 500px;

  h2 {
    font-family: 'Source Code Pro', monospace;
    font-size: 1.4rem;
    margin: 40px 0 30px 0;
  }

  p {
    line-height: 1.8rem;
    font-size: 0.8rem;
    padding: 0 30px;
    margin: 0 0 20px 0;
    word-break: keep-all;
  }

  span {
    margin: 0 0 10px 0;
  }

  .profile__wrapper {
    display: flex;

    & > img {
      width: 20px;
      position: absolute;
      left: calc(50% - 10px);
      padding-top: 100px;
    }
  }

  .profile {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    & > img {
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 50px;
      margin-bottom: 10px;
    }

    .profile__contact {
      display: flex;
      a {
        display: block;
        width: 30px;
        height: 30px;
        margin: 5px;
        padding: 7px;
        border-radius: 100%;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`;

const Header = (props) => {
  return (
    <StyledHeaderWrapper>
      <StyledHeader>
        <Typography type="h2" color="#333" textAlign="center">
          WE ARE GETTING MARRIED
        </Typography>
        <Typography type="p" color="#333" textAlign="center">
          오랜 기다림 속에 저희 두 사람, 서로에 대한 믿음과 이해와 사랑으로 새
          출발의 첫걸음을 내딛습니다. 참석하셔서 축복과 격려 주시면 더없는
          기쁨으로 간직하겠습니다.
        </Typography>

        <div className="profile__wrapper">
          <div className="profile">
            <img src={ImageBumhee} alt="" />
            <Typography type="span" color="#333" textAlign="center">
              신랑
            </Typography>
            <Typography
              type="span"
              color="#333"
              textAlign="center"
              fontWeight={'bold'}
            >
              정범희
            </Typography>
            <div className="profile__contact">
              <a href="" style={{ backgroundColor: '#83aebd ' }}>
                <img src={ImagePhone} alt="" />
              </a>
              <a href="" style={{ backgroundColor: '#83aebd ' }}>
                <img src={ImageSms} alt="" />
              </a>
            </div>
          </div>
          <img src={IconHeart} alt="" />
          <div className="profile">
            <img src={ImageJinyoung} alt="" />
            <Typography type="span" color="#333" textAlign="center">
              신부
            </Typography>
            <Typography
              type="span"
              color="#333"
              textAlign="center"
              fontWeight={'bold'}
            >
              김진영
            </Typography>
            <div className="profile__contact">
              <a href="" style={{ backgroundColor: '#c36a8a ' }}>
                <img src={ImagePhone} alt="" />
              </a>
              <a href="" style={{ backgroundColor: '#c36a8a ' }}>
                <img src={ImageSms} alt="" />
              </a>
            </div>
          </div>
        </div>

        <Typography type="h2" color="#333" textAlign="center">
          WEDDING DAY
        </Typography>
        <Typography type="h3" color="#333" textAlign="center">
          정범희 & 김진영
        </Typography>
        <Typography type="p" color="#333" textAlign="center">
          2019┃12┃29
        </Typography>
        <Typography type="p" color="#333" textAlign="center">
          야탑 | 메종 드 베르 8층 베르사유홀
        </Typography>
        <Typography type="p" color="#333" textAlign="center">
          12 : 30 pm
        </Typography>
      </StyledHeader>
    </StyledHeaderWrapper>
  );
};

export default Header;
