import React, { useState, useRef, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import Typography from 'components/Typography';
import useFirestoreQuery from 'hooks/useFirestoreQuery';
import styled from 'styled-components';
import firebase from 'firebase/app';
import horoscopeData from 'data/horoscope.json';

const StyledBorderWrapper = styled.div`
  margin: auto;
  display: flex;
`;

const StyledBorder = styled.div`
  max-width: 500px;

  h2 {
    font-family: 'Source Code Pro', monospace;
    font-size: 1.4rem;
    margin: 40px 0 30px 0;
  }
`;

const StyledCommentListWrapper = styled.div`
  height: auto;
`;

const StyledCommentList = styled.ul`
  margin: 0 0 40px 0;
  padding: 20px;
`;

const StyledCommentListItem = styled.li`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  padding: 10px 0;

  background: white;
  box-shadow: 3px 3px 6px #777;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .name_wrapper {
    display: flex;
    flex-direction: column;
  }

  .comment__name {
    display: inline-block;
    width: 100px;
    padding: 6px 10px;
    text-align: center;
    color: #505050;
    font-size: 1rem;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .comment__date {
    text-align: center;
    font-size: 0.8rem;
  }

  .comment__text {
    border-left: 1px solid #ddd;
    white-space: pre;
    font-size: 0.8rem;
    padding: 10px 10px;
    white-space: pre-wrap;
    max-width: 220px;
  }
`;

const StyledHoroscope = styled(animated.div)`
  font-family: 'Source Code Pro', monospace;
  font-size: 1.4rem;
  overflow: hidden;
  opacity: 0;
  height: 0;

  span {
    margin-bottom: 40px;
  }

  p {
    overflow: hidden;
    white-space: pre-wrap;
    padding: 10px;
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;

  input {
    -webkit-user-select: text;
    border: 1px solid #aaa;
    font-size: 1.2rem;
    padding: 10px;
    margin-bottom: 10px;
  }

  textarea {
    -webkit-user-select: text;
    border: 1px solid #aaa;
    font-size: 1.2rem;
    height: 120px;
    padding: 10px;
  }

  button {
    background-color: #5c628c;
    padding: 14px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
  }
`;

const Board = ({ comment }) => {
  const { data } = useFirestoreQuery(comment);
  const [horoscope, setHoroscope] = useState(-1);
  const horoscopeRef = useRef();
  const commentRef = useRef();
  const formRef = useRef();
  const [top, setTop] = useState(0);
  useEffect(() => {
    const { offsetTop } = horoscopeRef.current;
    setTop(offsetTop + 30);
  }, []);

  const [, setY] = useSpring(() => ({ y: top }));

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target[0].value;
    const text = e.target[1].value;

    var newCityRef = comment.doc();

    if (name.toLowerCase() !== 'test') {
      newCityRef.set({
        name: name,
        text: text,
        createdDate: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }

    e.target[0].value = '';
    e.target[1].value = '';

    setHoroscope(Math.floor(Math.random() * 30) + 1);

    setY({
      y: top,
      reset: true,
      from: { y: window.scrollY },
      onFrame: (props) => window.scroll(0, props.y),
    });
    commentRef.current.scrollTo(0, 0);
  };

  const updateFocus = (e) => {
    const { offsetTop } = formRef.current;
    setY({
      y: offsetTop,
      reset: true,
      from: { y: window.scrollY },
      onFrame: (props) => window.scroll(0, props.y),
    });
  };

  const openHoroscope = !!horoscope && horoscope >= 0;
  // const [bind, {height}] = useMeasure(
  const props = useSpring({
    config: { mass: 1, tension: 200, friction: 100 },
    opacity: openHoroscope ? 1 : 0,
    height: openHoroscope ? 500 : 0,
    marginTop: openHoroscope ? 40 : 0,
    marginBottom: openHoroscope ? 40 : 0,
    delay: 400,
  });

  return (
    <StyledBorderWrapper>
      <StyledBorder>
        {
          <StyledHoroscope style={props} ref={horoscopeRef}>
            <Typography type="span" color="#333" textAlign="center">
              오늘의 운세
            </Typography>
            <p>{horoscopeData[horoscope]}</p>
          </StyledHoroscope>
        }
        <Typography type="h2" color="#333" textAlign="center">
          방명록
        </Typography>
        <Typography type="h3" color="#333" textAlign="center">
          모두들 감사합니다. 행복하게 잘 살겠습니다.
        </Typography>
        {/* <StyledForm onSubmit={handleSubmit} ref={formRef}>
                    <input type="text" onFocus={updateFocus} name="name" id="name" placeholder="이름을 입력해주세요." minLength="2" maxLength="20" required/>
                    <textarea name="text" onFocus={updateFocus} id="text" placeholder="축하의 말을 전해주세요." minLength="2" maxLength="300" required />
                    <button type="submit">작성</button>
                </StyledForm> */}
        <StyledCommentListWrapper ref={commentRef}>
          {data && (
            <StyledCommentList>
              {data.docs.map((doc) => {
                if (doc.data().name.toLowerCase() === 'test') {
                  return null;
                }

                let t = new Date(1970, 0, 1);
                let time;
                if (!!doc.data().createdDate) {
                  t.setSeconds(doc.data().createdDate.seconds);
                  time = t.toISOString().substr(0, 10);
                } else {
                  time = null;
                }

                return (
                  <StyledCommentListItem key={doc.id}>
                    <div className="name_wrapper">
                      <span className={'comment__name'}>{doc.data().name}</span>
                      <span className={'comment__date'}>{time}</span>
                    </div>
                    <span className={'comment__text'}>{doc.data().text}</span>
                  </StyledCommentListItem>
                );
              })}
            </StyledCommentList>
          )}
        </StyledCommentListWrapper>
      </StyledBorder>
    </StyledBorderWrapper>
  );
};

export default Board;
