
import { useState, useEffect } from 'react';

const useFirestoreQuery = (comment) => {

    const [docState, setDocState] = useState({
        isLoading: true,
        data: null
    });

    useEffect(() => (
        comment.orderBy('createdDate', 'desc').onSnapshot(docs => {
            setDocState({
                isLoading: false,
                data: docs
            });
        })
    ), [comment]);

    return docState;
}

export default useFirestoreQuery;
