/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import styled from 'styled-components';

const StyledMap = styled.div`
    width: 100wh;
    height: 60vh;
    display: block;
`;

const StyledKakakoNaviButton = styled.div`
    height: 40px;
    background-color: #FFEB3B;
    text-align: center;
    font-weight: 600;

    button {
        width: 100%;
        height: 100%;
        display: block;
        cursor: pointer;
    }
`;

const Map = () => {
    useEffect(() => {
        let el = document.getElementById('map');
        // eslint-disable-next-line no-undef
        if (!!window.daum) {
            let map = new daum.maps.Map(el, {
                center: new daum.maps.LatLng(37.411653, 127.12972),
                level: 3,
            });
            // 마커가 표시될 위치입니다
            let markerPosition = new kakao.maps.LatLng(37.411653, 127.12972);

            // 마커를 생성합니다
            let marker = new kakao.maps.Marker({
                position: markerPosition,
            });

            // 마커가 지도 위에 표시되도록 설정합니다
            marker.setMap(map);

            var iwContent =
                    '<div style="padding:5px; line-height:1.6rem; margin-bottom: 5px;">메종 드 베르 8층<br /><div style="line-height: 1rem;"><a href="https://map.kakao.com/link/to/야탑%20메종%20드%20베르,37.411653, 127.12972" style="font-size: 0.8rem; color: #3f9bde; text-decoration: underline; font-weight: bold;" target="_blank">자세히보기</a><br /><a href="https://infograph.venngage.com/ps/Uc8ocrzpyc8/wedding" style="font-size: 0.8rem; color: #3f9bde; text-decoration: underline; font-weight: bold;" target="_blank">약도보기</a></div></div>', // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
                iwPosition = new kakao.maps.LatLng(37.411653, 127.12972); //인포윈도우 표시 위치입니다

            // 인포윈도우를 생성합니다
            var infowindow = new kakao.maps.InfoWindow({
                position: iwPosition,
                content: iwContent,
            });

            // 마커 위에 인포윈도우를 표시합니다. 두번째 파라미터인 marker를 넣어주지 않으면 지도 위에 표시됩니다
            infowindow.open(map, marker);
        }
    }, []);

    return (
        <React.Fragment>
            <StyledMap id="map"></StyledMap>
            <StyledKakakoNaviButton>
                <button
                    href="#"
                    onClick={() => {
                        Kakao.init('0c9f7a3223e9ce6623d550137b1f0bba');
                        // 카카오 로그인 버튼을 생성합니다.
                        new Kakao.Navi.start({
                            name: "메종 드 베르 야탑점 8층",
                            x: 37.411653,
                            y: 127.12972,
                            coordType: 'wgs84'
                        });
                    }}
                >카카오 내비게이션</button>
            </StyledKakakoNaviButton>
        </React.Fragment>
    );
};

export default Map;
