import React from 'react';
import styled from 'styled-components';

const CommonStyled = styled.text.attrs(props => ({
    fontSize: props.fontSize || '1em',
    fontWeight: props.fontWeight || 'normal',
    margin: props.margin || 0,
    padding: props.padding || 0,
    color: props.color || 'unset',
    textalign: props.textalign || 'unset',
    fontFamily: props.fontFamily || 'inherit',
}))`
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    color: ${props => props.color};
    text-align: ${props => props.textalign};
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    font-family: ${props => props.fontFamily};
`;

// H1 Component
const H1 = styled(CommonStyled).attrs((props) => ({
    fontSize: props.fontSize || '4em',
    textalign: props.textAlign || 'unset',
}))``;

const H2 = styled(CommonStyled).attrs(props => ({
    fontSize: props.fontSize || '3em',
    textalign: props.textAlign || 'unset',
}))``;

const H3 = styled(CommonStyled).attrs(props => ({
    fontSize: props.fontSize || '2em',
    textalign: props.textAlign || 'unset',
}))``;

const P = styled(CommonStyled).attrs(props => ({
    fontSize: props.fontSize || '1em',
    textalign: props.textAlign || 'left',
    fontWeight: props.fontWeight,
}))``;

const SPAN = styled(CommonStyled).attrs(props => ({
    fontSize: props.fontSize || '1em',
    textalign: props.textAlign || 'left',
}))``;

/**
 *
 *
 * @param { children, type, color, margin, padding, fontWeight, fontSize, textAlign } { children, type, color, margin, padding, fontWeight, fontSize, textAlign }
 * @returns
 */
const Typography = ({ children, type, color, margin, padding, fontWeight, fontSize, textAlign, fontFamily }) => {
    const props = { color, margin, padding, fontWeight, fontSize, textAlign, fontFamily };

    const renderElement = () => {
        let result = '';
        switch (type) {
            case 'h1':
                result = <H1 {...props} as={type}>{children}</H1>;
                break;

            case 'h2':
                result = <H2 {...props} as={type}>{children}</H2>;
                break;

            case 'h3':
                result = <H3 {...props} as={type}>{children}</H3>;
                break;

            case 'p':
                result = <P {...props} as={type}>{children}</P>;
                break;

            case 'span':
                result = <SPAN {...props} as={type}>{children}</SPAN>;
                break;

            default:
                break;
        }
        return result;
    };

    return renderElement(type);
};

export default Typography;
