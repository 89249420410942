import React from 'react';
import styled from 'styled-components';

const StyledImageModal = styled.div`
    position: fixed;
    background: rgba(0, 0, 0, 0.85);
    width: 100vw;
    height: 100vh;
    z-index: 100;
    cursor: pointer;
`;

function ImageModal({children, onClose}) {
    return (
        <StyledImageModal onClick={onClose}>
            {children}
        </StyledImageModal>
    )
}

export default ImageModal;
