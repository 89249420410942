import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from "react-router-dom";

import Typography from 'components/Typography';
import ImageModal from 'components/ImageModal';
import useWindowDimensions from 'hooks/useWindowDimensions';
import styled from 'styled-components';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ScaleLoader from 'react-spinners/ScaleLoader';


const ALBUM_IMAGE_LIST = {
    origins: [
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_fix_album_new_001.jpg?alt=media&token=9b5e3f5f-56b7-43b6-aa59-d84e12a3f8cf',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_fix_album_new_002.jpg?alt=media&token=b277c478-a5bc-4f12-97fe-84402d4d93ad',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_fix_album_new_003.jpg?alt=media&token=4c789c93-5118-4318-a76b-572a104b1de0',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_fix_album_new_004.jpg?alt=media&token=c6e921cd-02bb-4ae0-8bdf-32f63a2719b6',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_fix_album_new_005.jpg?alt=media&token=baabbe7a-bba4-4916-86e1-742de8b5ea0d',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_fix_album_new_006.jpg?alt=media&token=f9e82c80-ff31-4d43-99ef-7b433bde3d3c',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_fix_album_new_007.jpg?alt=media&token=ed258285-8385-4cbd-ad52-3df82a61ddeb',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_fix_album_new_008.jpg?alt=media&token=6b4f01b3-0783-4a2b-91c0-231a6bd8407e',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_fix_album_new_009.jpg?alt=media&token=221c1fd5-23db-4297-9dfc-69a1bc6ec9db',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_fix_album_new_010.jpg?alt=media&token=c34fc1ac-8696-4cdc-b1f4-72a82d27f023',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_fix_album_new_011.jpg?alt=media&token=ba0f7f30-759c-4bd1-b4b2-919fdf3a2937',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_fix_album_new_012.jpg?alt=media&token=f942bb6d-0733-4b8d-a30f-cff0a6e6fc4a',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_fix_album_new_013.jpg?alt=media&token=f37f790d-3f5a-4e31-82df-99b540ec8e7d',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_album_001.jpg?alt=media&token=84e12a87-c870-468b-ab40-242c508d357e',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_album_003.jpg?alt=media&token=6e77c903-2646-4e5b-8ca1-bf96a42044cd',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_album_004.jpg?alt=media&token=e64d0e4b-9dd6-4f5d-899d-d270cc3da156',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_album_005.jpg?alt=media&token=5851c6bd-eace-43f3-9c78-2ea5fa84ae3b',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_album_006.jpg?alt=media&token=5c2bc6fb-0c31-4f9d-99cf-6bdce2bc82f7',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_album_007.jpg?alt=media&token=e9ae78e1-440e-4cde-8586-e833f11c733f',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fresize_album_008.jpg?alt=media&token=eb7d2b69-679e-480f-8637-793657ca9bb7',
    ],
    mobiles: [
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_001.jpg?alt=media&token=027a4148-59d1-4225-957b-f834b65e52d4',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_002.jpg?alt=media&token=41a6c997-ff9f-4832-ba5e-440c2639b869',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_003.jpg?alt=media&token=9758b55e-3ba1-44a0-a160-f28e9a4fafaf',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_004.jpg?alt=media&token=d45c0409-890e-4b60-911b-e75a198a376a',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_005.jpg?alt=media&token=77145729-b9bc-44e2-ab53-21f26545dfef',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_006.jpg?alt=media&token=f967c408-101c-40c6-bb18-3fcd890b957d',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_007.jpg?alt=media&token=b0129cc2-2ec0-491d-816f-6ff4da89384b',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_008.jpg?alt=media&token=9ef61b22-343e-4a24-9723-59b1e9690b51',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_009.jpg?alt=media&token=524b9ab9-a202-4cb7-a9ec-55d66d0d95f8',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_010.jpg?alt=media&token=92afe484-e5b7-4a33-a020-901aff000f2c',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_011.jpg?alt=media&token=62802ff7-0a46-4829-b746-9f7abf193aca',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_012.jpg?alt=media&token=5d7e4992-3277-4aca-b6d2-1bde6f71608d',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_013.jpg?alt=media&token=0c48a88e-fe5d-450b-88ae-365c5bb84792',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_album_001.jpg?alt=media&token=7442906f-6870-4544-a3aa-a9ea63224eb8',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_album_003.jpg?alt=media&token=afc7cf70-6a4c-45f8-bd45-95614a457557',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_album_004.jpg?alt=media&token=a5cd12a7-a370-495a-b26f-26546f4d52ac',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_album_005.jpg?alt=media&token=756a1a9d-8e26-4970-acce-fbca6c13adcb',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_album_006.jpg?alt=media&token=15e95d17-01a3-463d-9472-f5ecbc6a529c',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_album_007.jpg?alt=media&token=7fcdae8b-0c15-42a2-84cf-758b865c336d',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_album_008.jpg?alt=media&token=338e2a7f-78b6-4055-8a2f-75ce9c9d1d76',
    ],
    thumbnails: [
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_fix_album_new_001_120x120.jpg?alt=media&token=8c90e74e-bf2a-4998-97ab-f0eada87b27a',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_fix_album_new_002_120x120.jpg?alt=media&token=0adcf1ee-67d8-48cf-a9e7-13bf56127c42',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_fix_album_new_003_120x120.jpg?alt=media&token=d0f1ac14-c811-4b91-930f-59137c8adf1b',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_fix_album_new_004_120x120.jpg?alt=media&token=cf4f1f8c-5ccb-44b6-867e-fc7142da8f58',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_fix_album_new_005_120x120.jpg?alt=media&token=083a7e4c-ede5-4358-bf19-b20e603b533d',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_fix_album_new_006_120x120.jpg?alt=media&token=c741cfcd-60f5-478d-a660-bedf48b41fbc',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_fix_album_new_007_120x120.jpg?alt=media&token=cd27d300-cdaa-478f-8217-455238e26947',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_fix_album_new_008_120x120.jpg?alt=media&token=4f4223e2-50fd-4198-8a17-13d009f9e326',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_fix_album_new_009_120x120.jpg?alt=media&token=722892ee-d79c-4e8c-bd3e-016b73d4cdd3',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_fix_album_new_010_120x120.jpg?alt=media&token=a474fc9e-3565-4e04-a28b-b1deb9419a5e',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_fix_album_new_011_120x120.jpg?alt=media&token=bc491ca5-9043-4700-b61c-d5a7e213167b',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_fix_album_new_012_120x120.jpg?alt=media&token=2efc9e3c-b222-41e5-8ae6-0d19fe021e55',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_fix_album_new_013_120x120.jpg?alt=media&token=5e947f31-f2e5-440d-a646-d63d9c87a549',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_album_001_120x120.jpg?alt=media&token=64b71bc4-43e6-4122-97d6-5ae622e001f2',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_album_003_120x120.jpg?alt=media&token=8dfb65f8-b086-4f74-a9be-a1e1c83d543c',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_album_004_120x120.jpg?alt=media&token=7f2d6c17-229c-439f-899a-c93d6561720b',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_album_005_120x120.jpg?alt=media&token=13098044-3cab-4c44-b47f-774f6128014f',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_album_006_120x120.jpg?alt=media&token=6341f722-ae74-419e-a8fc-09c736617a62',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_album_007_120x120.jpg?alt=media&token=8871252d-6bdc-4ec8-8c4c-59a8016923dd',
        'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fthumbnails%2Fresize_album_008_120x120.jpg?alt=media&token=22e436ee-b6bf-47d2-9d29-1cc8f2e33944',
    ],
};

const StyledHeader = styled.div`

    height: 30vh;
    min-height: 300px;

    h2 {
        font-size: 1.4rem;
        margin: 40px 0 30px 0;
    }

    & > div {
        width: 80%;
        margin: 0 auto;
    }


    .slick-prev:before,
    .slick-next:before {
        color: #2c2c46;
    }
`;

const StyledImageWrapper = styled.div`

    padding: 4%;

    &:focus {
        outline: 0;
    }
    & .slick-prev:before {
        color: red;
    }
`;

const StyledImageThumbnail = styled.div.attrs(props => ({
    width: props.width || '100%',
    height: props.height || '120px',
    backgroundSize: props.backgroundSize || "cover",
}))`
    background: ${props => props.src ? `url("${props.src}") no-repeat center center` : ""};
    background-size: ${props => props.backgroundSize};
    width: ${props => props.width};
    height: ${props => props.height};
    cursor: pointer;
`;

const StyledImage = styled.img`
    object-fit: contain;
    width: 100vw;
    height: 100vh;
`;

const StyledLeftArrowButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    font-size: 5rem;
    color: #fff;
    z-index: 200;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    width: 60px;
    height: 100vh;
    text-align: center;
    cursor: pointer;
    font-family: 'Source Code Pro',monospace;
`;

const StyledRightArrowButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    font-size: 5rem;
    color: #fff;
    z-index: 200;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    width:60px;
    height: 100vh;
    text-align: center;
    cursor: pointer;
    font-family: 'Source Code Pro',monospace;
`;

const Header = props => {

    const { width } = useWindowDimensions();

    let history = useHistory();
    let match = useRouteMatch("/img/:imageIndex");

    useEffect(() => {
        function preloading(imageArray) {
            let n = imageArray.length;
            for (let i = 0; i < n; i++) {
                let img = new Image();
                img.src = imageArray[i];
            }
        }
        preloading([
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_001.jpg?alt=media&token=027a4148-59d1-4225-957b-f834b65e52d4',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_002.jpg?alt=media&token=41a6c997-ff9f-4832-ba5e-440c2639b869',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_003.jpg?alt=media&token=9758b55e-3ba1-44a0-a160-f28e9a4fafaf',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_004.jpg?alt=media&token=d45c0409-890e-4b60-911b-e75a198a376a',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_005.jpg?alt=media&token=77145729-b9bc-44e2-ab53-21f26545dfef',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_006.jpg?alt=media&token=f967c408-101c-40c6-bb18-3fcd890b957d',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_007.jpg?alt=media&token=b0129cc2-2ec0-491d-816f-6ff4da89384b',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_008.jpg?alt=media&token=9ef61b22-343e-4a24-9723-59b1e9690b51',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_009.jpg?alt=media&token=524b9ab9-a202-4cb7-a9ec-55d66d0d95f8',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_010.jpg?alt=media&token=92afe484-e5b7-4a33-a020-901aff000f2c',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_011.jpg?alt=media&token=62802ff7-0a46-4829-b746-9f7abf193aca',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_012.jpg?alt=media&token=5d7e4992-3277-4aca-b6d2-1bde6f71608d',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_fix_album_new_013.jpg?alt=media&token=0c48a88e-fe5d-450b-88ae-365c5bb84792',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_album_001.jpg?alt=media&token=7442906f-6870-4544-a3aa-a9ea63224eb8',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_album_003.jpg?alt=media&token=afc7cf70-6a4c-45f8-bd45-95614a457557',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_album_004.jpg?alt=media&token=a5cd12a7-a370-495a-b26f-26546f4d52ac',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_album_005.jpg?alt=media&token=756a1a9d-8e26-4970-acce-fbca6c13adcb',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_album_006.jpg?alt=media&token=15e95d17-01a3-463d-9472-f5ecbc6a529c',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_album_007.jpg?alt=media&token=7fcdae8b-0c15-42a2-84cf-758b865c336d',
            'https://firebasestorage.googleapis.com/v0/b/wedding-invitation-e292b.appspot.com/o/images%2Fmobile_resize_album_008.jpg?alt=media&token=338e2a7f-78b6-4055-8a2f-75ce9c9d1d76',
        ]);
    }, []);

    // react-slick lib config
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: width > 600 ? 8 : 4,
        slidesToScroll: 4,
        lazyLoad: false,
        // rows: 2,
    };

    let open = false;
    let isLoading = false;
    let index = -1;

    const [modal, setModal] = useState(() => {
        return {
            open,
            isLoading,
            index,
        }
    });


    isLoading = modal.isLoading;
    if (!!match && match.isExact) {
        open = true;
        index = match.params.imageIndex;
    } else {
        open = false;
        index = -1;
    }

    return (
        <React.Fragment>
            <StyledHeader>

                <Typography type="h2" color="#333" textAlign="center" fontFamily="'Source Code Pro',monospace">
                    GALLERY
                </Typography>
                <div>
                    <Slider {...settings}>
                        {ALBUM_IMAGE_LIST.thumbnails.map((url, index) => (
                            <StyledImageWrapper
                                key={index}
                                onClick={() => {
                                    setModal({
                                        open: true,
                                        isLoading: true,
                                        index: index,
                                    })
                                    history.push(`/img/${index}`);
                                }}>
                                <StyledImageThumbnail
                                    src={url}
                                ></StyledImageThumbnail>
                            </StyledImageWrapper>
                        ))}
                    </Slider>
                </div>
            </StyledHeader>
            {open &&
                <React.Fragment>
                    <ImageModal
                        onClose={() => {
                            setModal({ open: false, isLoading: false, index: -1 });
                            history.push("/");
                        }}
                    >
                        <StyledImage
                            src={width > 1000 ? ALBUM_IMAGE_LIST.origins[index] : ALBUM_IMAGE_LIST.mobiles[index]}
                            onLoad={() => setModal({
                                open: true,
                                isLoading: false,
                                index: index,
                            })}
                        ></StyledImage>
                    </ImageModal>
                    <StyledLeftArrowButton
                        onClick={() => {
                            const targetIndex = parseInt(index) - 1 < 0 ? ALBUM_IMAGE_LIST.origins.length - 1 : parseInt(index) - 1;
                            setModal({ open: true, isLoading: true, index: targetIndex });
                            history.replace(`/img/${targetIndex}`);
                        }}
                    >
                        <span>{'<'}</span>
                    </StyledLeftArrowButton>
                    <StyledRightArrowButton
                        onClick={() => {
                            const targetIndex = parseInt(index) + 1 >= ALBUM_IMAGE_LIST.origins.length ? 0 : parseInt(index) + 1;
                            setModal({ open: true, isLoading: true, index: targetIndex });
                            history.replace(`/img/${targetIndex}`);
                        }}
                    >
                        <span>{'>'}</span>
                    </StyledRightArrowButton>
                    <ScaleLoader
                        css={{
                            zIndex: 200,
                            position: 'fixed',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                        sizeUnit={"px"}
                        size={150}
                        color={'#eee'}
                        loading={isLoading}
                    />
                </React.Fragment>
            }
        </React.Fragment>
    );
};

export default Header;
